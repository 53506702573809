
    import {Component, Prop} from 'vue-property-decorator';
    import Category from '@/components/Category/Category.vue';
    import {namespace} from 'vuex-class';
    import {BaseScript} from '@/components/BaseScript';

    const category = namespace('category');

    @Component({
        name: 'Roman',
        components: {
            Category,
        },
    })
    export default class Roman extends BaseScript {
        @category.Action('setCurrentCategoryFunc') private readonly setCurrentCategoryFunc!: any;
        @category.Action('setSearchData') private readonly  setSearchData!: any;
        @Prop(Object) private readonly searchGlobalProp!: any;

        constructor() {
          super();
        }

        /**
         * @private
         * @return {void}
         */
        private mounted(): void {
            this.setSearchData();
            this.setCurrentCategoryFunc('romans');
        }
    }
